import React from "react"
import styled from "styled-components"
import {
  Form,
  Input,
  MailFilled,
  LockFilled,
  Button,
  Checkbox,
  Row,
  Col,
} from "../../lib/ui"
const { Item: AntDFormItem } = Form

const Item = styled(AntDFormItem)`
  width: 100%;
`
const RegisterForm = () => {
  const { Item } = Form
  const handleSubmit = () => {}
  return (
    <Form onSubmit={handleSubmit} style={{ maxWidth: "600px", width: "100%" }}>
      <Row>
        <Col span={12}>
          <Item name="fullname">
            <Input placeholder="用戶名稱" autoComplete="off" />
          </Item>
        </Col>
        <Col span={12}>
          <Item name="location">
            <Input placeholder="地圖暱稱" autoComplete="off" />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Item name="email">
            <Input placeholder="電郵" autoComplete="off" />
          </Item>
        </Col>
        <Col span={12}>
          <Item name="phone">
            <Input placeholder="電話" autoComplete="off" />
          </Item>
        </Col>
      </Row>
    </Form>
  )
}

export default RegisterForm
