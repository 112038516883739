import React from "react"
import { Responsive, Row, Col } from "../lib/ui"
import Register from "../components/Register"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"

const RegisterPage = () => (
  <Layout>
    <Responsive.Desktop>
      <SEO title="Registartion" />
      <Row>
        <Col offset={3} span={18} style={{ justifyContent: "center" }}>
          <Register />
        </Col>
      </Row>
    </Responsive.Desktop>
  </Layout>
)

export default RegisterPage
